import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const platvormid = () => (
  <Layout>
    <SEO title="Epopõrandad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>PLATVORMID</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/platvormid/platvormid/"
          activeStyle={{ border: "30px solid green" }}
        >
          <div className="epo antistaatilised">
            <h2>Platvormid</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link to="/platvormid/kaarjalgadega/">
          <div className="epo epohoordemass">
            <h2>Käärjalgadega platvormid</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/platvormid/motoriseeritud/">
          <div className="epo isetasanduv-epomasskate">
            <h2>Mootoriga platvormid</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link to="/platvormid/tellingplatvormid/">
          <div className="epo treppidele">
            <h2>Telling-platvormid</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/platvormid/raam/">
          <div className="epo dekoratiivporandad">
            <h2>Lavade raamid</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
      </div>

      <div className="epoporandad-text">
        Mobiilplatvormi süsteemis on kolme tüüpi lavaelemente:
        <ul>
          <li>platvorm;</li>
          <li>teleskoopjalgade platvorm</li>
          <li>käärjalgadega platvorm</li>
        </ul>
        Sisuliselt erinevad platvormid ja lavaelemendid nende tugimehhanismide
        ja rakendusalade poolest: vabaõhuüritused, teatrid, stuudiod,
        ühiskondlikud ja mitmeotstarbelised saalid, messi- ja tööstushooned,
        toitlustusasutused, tsirkuseettevõtted, moeüritused, hotellid jne.
      </div>
    </div>
  </Layout>
)

export default platvormid
